// @flow
import * as React from 'react';
import { useEffect, memo } from 'react';
import { navigate } from '@reach/router';
import { captureException } from '@sentry/react';

import {
    responceInterceptors,
    requestInterceptors
} from '../../api/interceptor';
import { logOut, authSuccess } from '../../actions/auth';
import typedConnect from '../../utils/typedConnect';
import { codes } from '../../api/errors';
import Router, { getPathByName } from '../../router/';
import { AuthChecker } from '../../components/AuthChecker/AuthChecker';
import type { IAuth } from '../../api/utils/Auth';

function createResponseErr(response: Response) {
    return new Error(`Request failed with status code ${response.status}`);
}

const excludePaths = ['/terms'];

type Props = {
    auth: IAuth,
    logOut: () => mixed,
    setUser: typeof authSuccess
};

const Shell = memo((props: Props) => {
    const { logOut, setUser, auth } = props;

    useEffect(() => {
        // Возможно это должно быть где-то в другом месте
        responceInterceptors.use({
            onResolve: response => {
                /**
                 * Миграционное API обрабатывается в классе Auth и должно быть удалено
                 */
                if (auth.isMigrationResponse(response)) {
                    return Promise.resolve(response);
                }

                if (response.status === codes.REQUEST_ERROR) {
                    return response.json().then(data => {
                        captureException(createResponseErr(response), {
                            extra: {
                                apiErrorCode: data.code,
                                apiErrorMessage: data.message
                            }
                        });

                        switch (data.code) {
                            case codes.AUTH_REQUIRED:
                                logOut();
                                navigate(getPathByName('auth2'));
                                break;
                            case codes.NOT_ENOUGH_AUTHORITY:
                                navigate(getPathByName('feed'));
                                break;
                            default:
                                break;
                        }

                        return Promise.reject(data);
                    });
                } else if (!response.ok) {
                    captureException(createResponseErr(response));
                }

                return Promise.resolve(response);
            },
            onReject: response => {
                let report = response;

                if (response instanceof Response) {
                    report = new Error(
                        `Request failed with status code ${response.status}`
                    );
                }

                captureException(report);

                return Promise.reject(response);
            }
        });
    }, [logOut, auth]);

    useEffect(() => {
        requestInterceptors.use(({ url, options }) => {
            return auth
                .getToken()
                .then(token => {
                    const headers: HeadersInit = {
                        ...options.headers,
                        Authorization: `Bearer ${token}`
                    };
                    return {
                        url,
                        options: {
                            ...options,
                            headers
                        }
                    };
                })
                .catch(() => Promise.resolve({ url, options }));
        });
    }, [auth]);

    const handleAlreadySignedIn = React.useCallback(user => setUser(user), [
        setUser
    ]);

    return (
        <AuthChecker
            auth={auth}
            onAlreadySignedIn={handleAlreadySignedIn}
            excludePaths={excludePaths}
        >
            {Router()}
        </AuthChecker>
    );
});

export default typedConnect({
    mapState: state => ({
        user: state.auth.user
    }),
    actionCreators: {
        setUser: authSuccess,
        logOut
    }
})(Shell);
