// @flow

// TODO: тут могут быть неудобства с возвращаемым типом
type Handler<T, K = mixed> = (arg: T) => Promise<K>;

type RequestHandlerParams = { url: string, options: RequestOptions };
type RequestHandler = Handler<RequestHandlerParams, RequestHandlerParams>;

type ResponseHandlers = {
    onResolve: Handler<Response, Response>,
    onReject?: Handler<mixed, Response>
};

interface Registry<T> {
    use: (handlers: T) => () => mixed;
    get: () => Set<T>;
}

function makeInterceptorsRegistry<T: {}>(storage: Set<T>): Registry<T> {
    const use = handlers => {
        storage.add(handlers);

        return () => storage.delete(handlers);
    };

    const get = () => storage;

    return {
        use,
        get
    };
}

export const responceInterceptors = makeInterceptorsRegistry<ResponseHandlers>(
    new Set()
);
export const requestInterceptors = makeInterceptorsRegistry<RequestHandler>(
    new Set()
);

export const handleResponse = (promise: Promise<Response>) => {
    const interceptors = responceInterceptors.get();
    interceptors.forEach(({ onResolve, onReject }) => {
        promise = promise.then(onResolve, onReject);
    });

    return promise;
};

export const handleRequest = (
    url: string,
    options: RequestOptions
): Promise<RequestHandlerParams> => {
    const interceptors = requestInterceptors.get();
    let promise = Promise.resolve({ options, url });
    interceptors.forEach(handler => {
        promise = promise.then(handler);
    });

    return promise;
};
